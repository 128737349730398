.link {
    stroke: #000;
    stroke-width: 1.5px;
  }
  
.node {
  cursor: move;
  fill: #ccc;
  stroke: #000;
  stroke-width: 1.5px;
}
  
.node.fixed {
  fill: #f00;
}